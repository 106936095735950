import React from 'react';

export const pricings = [
  {
    title: 'One week',
    annual: 108,
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> sections</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    isHighlighted: false,
  },
  {
    title: 'One month',
    annual: 310,
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> sections</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    isHighlighted: true
  },
  {
    title: 'One year',
    annual: 1938,
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> sections</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    isHighlighted: false
  }
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
];

export const plans = [
  {
    title: 'Move deals through your funnel – fast',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'On demand services right at your front-door',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'Online 24/7 support',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
];
